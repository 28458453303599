import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Globals } from './../globals';

@Injectable({
  providedIn: 'root',
})
export class AuthGuardService implements CanActivate {

  public objData: any;

  constructor(private router: Router, private global: Globals) {

  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> | Promise<boolean> | boolean {

    this.objData = this.global.decryptObject(localStorage.getItem('user_data'));

    const currentUserId = this.objData.x_key;

    if (currentUserId !== undefined && currentUserId != null) {

      return true;
    } else {

      this.router.navigate(['/']);
    }

  }
}


