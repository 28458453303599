import { of as observableOf, Observable } from 'rxjs';
import { Contacts, RecentUsers, UserData } from '../data/users';

export class UserService extends UserData {
	private time: Date = new Date;
	public objData: any;

	private users = {
		nick: { name: this.objData.first_name + ' ' + this.objData.last_name, picture: this.objData.image },
	};
	private types = {
		mobile: 'mobile',
		home: 'home',
		work: 'work',
	};
	private contacts: Contacts[] = [
		{ user: this.users.nick, type: this.types.mobile },

	];
	private recentUsers: RecentUsers[] = [
		{ user: this.users.nick, type: this.types.mobile, time: this.time.setHours(5, 29) },
	];

	getUsers(): Observable<any> {
		return observableOf(this.users);
	}

	getContacts(): Observable<Contacts[]> {
		return observableOf(this.contacts);
	}

	getRecentUsers(): Observable<RecentUsers[]> {
		return observableOf(this.recentUsers);
	}
}
