// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: true,
  // authUrl:'http://dialogs.grtidea.com:3310/'
  // authUrl:'http://oneclicksales.xyz:3310/'
  // authUrl: "http://18.189.22.23:3310/"
  // authUrl:'http://localhost:8989/'

  // LIVE SERVER-v1.2
  // authUrl: "http://3.19.53.93:3310/",

  // LOCAL SERVER
  // authUrl: "http://oneclickitmarketing.co.in:3310/",

  // LIVE SERVER--v1.3
  // authUrl: "http://3.19.53.93:4320/",
  authUrl: "http://stageapi-d.klee.com/",
  //authUrl: "http://dialogs.klee.com/",
  //authUrl: "http://localhost:8055/",
  //authUrl:"http://3.35.158.7:3310"




  // authUrl: "http://dialogs2.grtidea.com:4320/",
  // authUrl: "http://dev.dialogs2.grtidea.com:8055/",

  // NEW ONE BY CLIENT
  // authUrl: "http://18.220.70.100:4320/",

  // authUrl: "http://localhost:3310/"
};
