import { ExtraOptions, RouterModule, Routes } from "@angular/router";
import { NgModule } from "@angular/core";
import { AuthGuardService } from "./guards/auth-guard.service";

import {
  NbAuthComponent,
  NbLoginComponent,
  NbLogoutComponent,
  NbRegisterComponent,
  NbRequestPasswordComponent,
  NbResetPasswordComponent
} from "@nebular/auth";

const routes: Routes = [
  {
    path: `pages`,
    canActivate: [AuthGuardService],
    loadChildren: () =>
      import(`../app/pages/pages.module`).then(m => m.PagesModule)
  },
  {
    path: "",
    component: NbAuthComponent,
    children: [
      {
        path: "",
        loadChildren: () =>
          import("./auth/auth.module").then(mod => mod.AuthModule)
      },
      {
        path: "register",
        component: NbRegisterComponent
      },
      {
        path: "logout",
        component: NbLogoutComponent
      },
      {
        path: "reset-password",
        component: NbResetPasswordComponent
      }
    ]
  },
  { path: "", redirectTo: "pages", pathMatch: "full" },
  { path: "**", redirectTo: "pages" }
];

const config: ExtraOptions = {
  useHash: false
};

@NgModule({
  imports: [RouterModule.forRoot(routes, config)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
